
<template>
  <div class="wrapper">
    <notifications></notifications>
    <side-bar>
      <template slot="links">
        <!-- <sidebar-item :link="{name: 'Experimental'}"><sidebar-item :link="{name: 'Experimental Item 1', path: '/experimentalItem1'}"></sidebar-item></sidebar-item> -->
        <!-- <sidebar-item :link="{name: 'Experimental'}">
          <sidebar-item
            :link="{
              name: 'Experiment Upload Photos',
              path: '/experimentUploadPhotos'}">
          </sidebar-item>
        </sidebar-item> -->
        <sidebar-item
          v-if="user_role != 12"
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-shop text-white',
            path: '/mainDashboard',
          }"
        >
          <!-- <sidebar-item
            :link="{
              name: 'Map Dashboard',
              path: '/mapDashboard',
            }"
          ></sidebar-item> -->
          <!-- <sidebar-item
            :link="{
              name: 'Consolidated Dashboard',
              path: '/consolidatedDashboard',
            }"
          ></sidebar-item> -->
          <!-- <sidebar-item
            :link="{
              name: 'Production Report',
              path: '/productionReport',
            }"
          ></sidebar-item> -->
          <!-- <sidebar-item
            :link="{
              name: 'Cycle Time Report',
              path: '/cycleTimeReport',
            }"
          ></sidebar-item> -->
          <!-- <sidebar-item
            :link="{ name: 'Overview Dashboard', path: '/overviewDashboard' }"></sidebar-item> -->
          <!-- <sidebar-item
            :link="{ name: 'Project Dashboard - W.I.P', path: '/projectListing' }"></sidebar-item>
          <sidebar-item
            :link="{ name: 'Group Dashboard - W.I.P', path: '/projectListing' }"></sidebar-item>
          <sidebar-item
            :link="{ name: 'Site Dashboard - W.I.P', path: '/projectListing' }"></sidebar-item> -->
        </sidebar-item>

        <!-- <sidebar-item
          v-if="user_role != 12"
          :link="{
            name: 'Project-Group-Site',
            icon: 'ni ni-building text-green',
          }"
        >
          <sidebar-item
            v-if="project_module.project_listing == 'YES'"
            :link="{ name: 'Project', path: '/projectListing' }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="project_module.project_group_listing == 'YES'"
            :link="{ name: 'Project Group', path: '/projectGroupListing' }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="project_module.project_site_listing == 'YES'"
            :link="{ name: 'Project Site', path: '/projectSiteListing' }"
          >
          </sidebar-item>
        </sidebar-item>
        <sidebar-item
          v-else
          :link="{
            name: 'Project Site',
            icon: 'ni ni-building text-green',
          }"
        >
          <sidebar-item
            :link="{
              name: 'Project Site',
              path: '/projectSiteListingContractor',
            }"
          >
          </sidebar-item>
        </sidebar-item> -->

        <sidebar-item
          v-if="user_role != 12"
          :link="{
            name: 'Projects',
            icon: 'ni ni-building text-green',
          }"
        >
          <sidebar-item
            v-if="project_module.project_listing == 'YES'"
            :link="{ name: 'C&I Projects', path: '/projectListingCNI' }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="project_module.project_listing == 'YES'"
            :link="{ name: 'Solar Nova', path: '/projectListingSN' }"
          >
            <sidebar-item
              v-if="project_module.project_listing == 'YES'"
              :link="{ name: 'SN2', path: '/projectListingSN2' }"
            >
            </sidebar-item>
            <sidebar-item
              v-if="project_module.project_listing == 'YES'"
              :link="{ name: 'SN3', path: '/projectListingSN3' }"
            >
            </sidebar-item>
            <sidebar-item
              v-if="project_module.project_listing == 'YES'"
              :link="{ name: 'SN5', path: '/projectListingSN5' }"
            >
            </sidebar-item>
          </sidebar-item>
          <sidebar-item
            v-if="project_module.project_listing == 'YES' && user_role == 3"
            :link="{ name: 'Opportunities'}"
          >
          <!-- , path: '/projectListingCRM'  -->
            <sidebar-item
              v-if="project_module.project_listing == 'YES'"
              :link="{ name: 'Opportunities Open', path: '/opportunityListingOpen' }"
            >
            </sidebar-item>
            <sidebar-item
              v-if="project_module.project_listing == 'YES'"
              :link="{ name: 'Opportunities Won', path: '/opportunityListingWon' }"
            >
            </sidebar-item>
            <sidebar-item
              v-if="project_module.project_listing == 'YES'"
              :link="{ name: 'Opportunities Lost', path: '/opportunityListingLost' }"
            >
            </sidebar-item>
            <sidebar-item
              v-if="project_module.project_listing == 'YES'"
              :link="{ name: 'Opportunities Archived', path: '/opportunityListingArchived' }"
            >
            </sidebar-item>
          </sidebar-item>
        </sidebar-item>
        <sidebar-item
          v-else
          :link="{
            name: 'Project Site',
            icon: 'ni ni-building text-green',
          }"
        >
          <sidebar-item
            :link="{
              name: 'Project Site',
              path: '/projectSiteListingContractor',
            }"
          >
          </sidebar-item>
        </sidebar-item>

        <!-- <sidebar-item
          :link="{
            name: 'Transaction',
            icon: 'ni ni-bullet-list-67 text-black',
          }"
        > -->
          <!-- <sidebar-item
            v-if="site_transaction_module.site_task_listing == 'YES'"
            :link="{ name: 'Site Task', path: '/TaskListing' }"
          >
          </sidebar-item> -->
          <!-- <sidebar-item v-if="site_transaction_module.site_document_listing == 'YES'"
            :link="{ name: 'Site Document', path: '/siteDocumentListing' }">
          </sidebar-item> -->
        <!-- </sidebar-item> -->

        <sidebar-item
          v-if="
            user_role == 1 ||
            user_role == 3 ||
            user_role == 9 ||
            user_role == 10 ||
            user_role == 15 ||
            user_role == 16
          "
          :link="{
            name: 'Setup',
            icon: 'ni ni-folder-17 text-red',
          }"
        >
          <sidebar-item
            v-if="setup_module.project_engineer_group_listing == 'YES' && user_role != 16"
            :link="{ name: 'Group of Project Engineers', path: '/setups/projectEngineerGroupListing' }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="setup_module.module_brand_listing == 'YES' && user_role != 16"
            :link="{ name: 'Module Brand', path: '/setups/moduleBrandListing' }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="setup_module.developer_listing == 'YES' && user_role != 16"
            :link="{ name: 'Developer', path: '/setups/developerListing' }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="setup_module.contractor_listing == 'YES' && user_role != 16"
            :link="{ name: 'Contractor', path: '/setups/contractorListing' }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="setup_module.user_listing == 'YES' && user_role != 16"
            :link="{ name: 'User', path: '/setups/UserListing' }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="setup_module.project_type_listing == 'YES' && user_role != 16"
            :link="{ name: 'Project Type', path: '/setups/projectTypeListing' }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="setup_module.project_category_listing == 'YES' && user_role != 16"
            :link="{
              name: 'Project Category',
              path: '/setups/projectCategoryListing',
            }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="setup_module.safety_observation_category_listing == 'YES'"
            :link="{
              name: 'Safety Observation Category',
              path: '/setups/safetyObservationCategoryListing',
            }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="setup_module.safety_observation_category_listing == 'YES' && user_role != 16"
            :link="{
              name: 'Safety Observation Priority',
              path: '/setups/safetyObservationPriorityListing',
            }"
          >
          </sidebar-item>
          <!-- <sidebar-item
            :link="{ name: 'Import Task & Document', path: '/importNew' }">
          </sidebar-item> -->
        </sidebar-item>

        <sidebar-item
          v-if="
            user_role == 1 ||
            user_role == 3
          "
          :link="{
            name: 'Audit Logs',
            icon: 'ni ni-vector text-red',
          }"
        >

          <!-- v-if="setup_module.module_brand_listing == 'YES'" -->
        
          <sidebar-item
            :link="{ name: 'Audit Log Document', path: '/audits/auditLogDocumentListing' }"
          >
          </sidebar-item>

          <sidebar-item
            :link="{ name: 'Audit Log Project', path: '/audits/auditLogProjectListing' }"
          >
          </sidebar-item>

          <sidebar-item
            :link="{ name: 'Audit Log Setup', path: '/audits/auditLogSetupListing' }"
          >
          </sidebar-item>

          <sidebar-item
            :link="{ name: 'Audit Log Task', path: '/audits/auditLogTaskListing' }"
          >
          </sidebar-item>

          <sidebar-item
            :link="{ name: 'Audit Log Template', path: '/audits/auditLogTemplateListing' }"
          >
          </sidebar-item>

          <sidebar-item
            :link="{ name: 'Audit Log Safety Inspection', path: '/audits/auditLogSafetyInspectionListing' }"
          >
          </sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Template',
            icon: 'ni ni-collection text-blue',
          }"
          v-if="
            user_role == 1 ||
            user_role == 3 ||
            user_role == 9 ||
            user_role == 10 ||
            user_role == 15 ||
            user_role == 8 ||
            user_role == 19
          "
        >
          <sidebar-item
            v-if="template_module.milestone_template_listing == 'YES'"
            :link="{
              name: 'Milestone Template',
              path: '/templates/milestoneTemplateListing',
            }"
          />

          <sidebar-item
            v-if="template_module.task_template_listing == 'YES'"
            :link="{
              name: 'Site Task Template',
              path: '/templates/taskTemplateListing',
            }"
          />

          <!-- <sidebar-item v-if="template_module.document_template_listing == 'NO'"
          :link="{ name: 'Site Document Template', path: '/templates/documentTemplateListing' }"/> -->

          <sidebar-item
            v-if="false"
            :link="{
              name: 'Site Document Template',
              path: '/templates/documentTemplateListing',
            }"
          />

          <sidebar-item
            v-if="template_module.project_task_template_listing == 'YES'"
            :link="{
              name: 'Project Task Template',
              path: '/templates/ProjectTaskTemplateListing',
            }"
          />

          <!-- <sidebar-item v-if="template_module.project_document_template_listing == 'NO'"
          :link="{ name: 'Project Document Template', path: '/templates/projectDocumentTemplateListing' }"/> -->

          <sidebar-item
            v-if="false"
            :link="{
              name: 'Project Document Template',
              path: '/templates/projectDocumentTemplateListing',
            }"
          />
        </sidebar-item>

        <!-- UPDATE WITH APPROPRIATE ACCESS RIGHTS -->
        <sidebar-item
          :link="{
            name: 'PRM',
            icon: 'ni ni-cloud-upload-96 text-blue',
          }"
          v-if="
            user_role != 12
          "
        >          
          <sidebar-item
            v-if="project_module.project_listing == 'YES'"
            :link="{
              name: 'Import PRM Records',
              path: '/prm/importPRMRecords',
            }"
          />
          <sidebar-item
            v-if="project_module.project_listing == 'YES'"
            :link="{
              name: 'Create Projects',
              path: '/prm/createProjectsFromPrm',
            }"
          />
        </sidebar-item>


        <sidebar-item
          :link="{
            name: 'Health & Safety',
            icon: 'ni ni-settings text-blue',
          }"
        >
          <sidebar-item
            v-if="health_safety_module.safety_observation_listing == 'YES'"
            :link="{
              name: 'Safety Inspection',
              path: '/healthSafety/safetyObservationListing',
            }"
          />
          <sidebar-item
            v-if="health_safety_module.safety_observation_report_listing == 'YES'"
            :link="{
              name: 'Safety Inspection Report',
              path: '/healthSafety/safetyObservationReport',
            }"
          />
          <sidebar-item
            v-if="health_safety_module.safety_observation_listing == 'YES'"
            :link="{
              name: 'My Open Safety Observations',
              path: '/healthSafety/safetyObservationItemOpenPendingListing',
            }"
          />
          <sidebar-item
            v-if="health_safety_module.toolbox_meeting_listing == 'YES'"
            :link="{
              name: 'Toolbox Meeting',
              path: '/healthSafety/toolboxMeetingListing',
            }"
          />
        </sidebar-item>
        <!-- {{template_module}} -->
      </template>

      <!-- <template slot="links-after" >
        <hr class="my-3" >
        <h6 class="navbar-heading p-0 text-muted" style="background-color: #033639;" >Documentation</h6>

        <b-nav class="navbar-nav mb-md-3" style="background-color: #033639;">
          <b-nav-item href="https://www.creative-tim.com/learning-lab/bootstrap-vue/quick-start/argon-dashboard">

              <i class="ni ni-spaceship text-white"></i>
              <b-nav-text class="p-0" style="color: white;">Getting started</b-nav-text>
          </b-nav-item>
          <b-nav-item href="https://www.creative-tim.com/learning-lab/bootstrap-vue/colors/argon-dashboard">

              <i class="ni ni-palette"></i>
              <b-nav-text class="p-0">Foundation</b-nav-text>
          </b-nav-item>
          <b-nav-item href="https://www.creative-tim.com/learning-lab/bootstrap-vue/avatar/argon-dashboard">

              <i class="ni ni-ui-04"></i>
              <b-nav-text class="p-0">Components</b-nav-text>
          </b-nav-item>
          <b-nav-item href="https://www.creative-tim.com/learning-lab/bootstrap-vue/charts/argon-dashboard">
              <i class="ni ni-chart-pie-35"></i>
              <b-nav-text class="p-0">Plugins</b-nav-text>
          </b-nav-item>
        </b-nav>
      </template> -->
    </side-bar>
    <div class="main-content">
      <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>

      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import { FadeTransition } from "vue2-transitions";
import {
  LoginURL,
  clientId,
  clientSecret,
  apiDomain,
  getHeader,
  getcurrentDate,
} from "@/assets/js/config.js";
import axios from "axios";
import ErrorFunctionMixin from "@/assets/js/errorFunctionMix.js";
import loginFunctionMix from "@/assets/js/loginFunctionMix.js";
import SidebarItem from "../../components/SidebarPlugin/SidebarItem.vue";

export default {
  mixins: [ErrorFunctionMixin, loginFunctionMix],
  components: {
    DashboardNavbar,
    ContentFooter,
    DashboardContent,
    FadeTransition,
    SidebarItem,
  },
  created() {
    //var project_category_access = JSON.parse(window.localStorage.getItem('project_category_access'))
    //this.setup_module.project_category_listing = project_category_access.access_listing
    this.getLoginUserRoleAccessMenu();
    var userInfo = JSON.parse(window.localStorage.getItem("loginUser"));
    this.user_role = userInfo.role_id;

    // this.getProjectType();
  },
  data() {
    return {
      user_role: null,
      setup_modules: [
        {
          developer_module: null,
          developer_listing: 0,
        },
      ],
      project_types: [],
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },

    // getProjectType() {
    //   axios
    //     .get(apiDomain + "setup/project_type", { headers: getHeader() })
    //     .then((response) => {
    //       if (response.status === 200) {
    //         this.project_types = response.data.data;
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error.response.status);
    //       this.errorFunction(error, "Project Type");
    //     });
    // },
  },
  mounted() {
    this.initScrollbar();
  },
};
</script>
<style lang="scss">
</style>
